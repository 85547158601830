var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content receipt-note-list"},[_c('section',{staticStyle:{"margin-bottom":"1%"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.createHandle}},[_c('i',{staticClass:"icon iconfont icon-new",staticStyle:{"margin-right":"5px"}}),_vm._v(" "+_vm._s(_vm.$t('新增付款单'))+" ")])],1),_c('div',{staticClass:"form-content"},[_c('a-row',{staticStyle:{"margin-top":"15px"},attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":6}},[_c('a-input',{attrs:{"allow-clear":"","placeholder":_vm.$t('收款单号')},model:{value:(_vm.formData.receiptNoteSn),callback:function ($$v) {_vm.$set(_vm.formData, "receiptNoteSn", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"formData.receiptNoteSn"}})],1),_c('a-col',{attrs:{"span":6}},[_c('CommonSelect',{attrs:{"isBu":true,"list":_vm.$store.state.settlementBuList,"code":_vm.formData.businessUnitCode,"name":_vm.formData.businessUnitName,"placeholder":_vm.$t(`收款组织`)},on:{"update:code":function($event){return _vm.$set(_vm.formData, "businessUnitCode", $event)},"update:name":function($event){return _vm.$set(_vm.formData, "businessUnitName", $event)}}})],1),_c('a-col',{attrs:{"span":6}},[_c('CommonQuerySelect',{attrs:{"api":"getCommonList","placeholder":_vm.$t('客户'),"code":_vm.formData.customerCode,"params":{
            businessUnitCode: _vm.formData.businessUnitCode,
            tableName: 'customer',
          }},on:{"update:code":function($event){return _vm.$set(_vm.formData, "customerCode", $event)}}})],1),_c('a-col',{attrs:{"span":6}},[_c('CommonSelect',{attrs:{"placeholder":_vm.$t('单据类型'),"list":_vm.receipt_note_type,"code":_vm.formData.orderType},on:{"update:code":function($event){return _vm.$set(_vm.formData, "orderType", $event)}}})],1)],1),_c('a-row',{staticClass:"mt10",attrs:{"gutter":16}},[_c('a-col',{attrs:{"span":6}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.searchHandle(1)}}},[_c('i',{staticClass:"iconfont",staticStyle:{"margin-right":"5px"}},[_vm._v("")]),_vm._v(" "+_vm._s(_vm.$t(`查询`))+" ")]),_c('a-button',{on:{"click":_vm.reset}},[_c('a-icon',{attrs:{"type":"redo"}}),_vm._v(" "+_vm._s(_vm.$t(`重置`))+" ")],1)],1)],1)],1),_c('CommonTable',{ref:"CommonTableRef",attrs:{"statusList":_vm.receipt_note_status,"columns":_vm.columns,"loading":_vm.loading,"dataSource":_vm.tableData,"scroll":{ x: 1500, y: _vm.scrollHeight },"showSelect":true,"selectedRowKeys":_vm.selectedRowKeys,"total":_vm.total,"rowKey":"id"},on:{"update:columns":function($event){_vm.columns=$event},"update:selectedRowKeys":function($event){_vm.selectedRowKeys=$event},"update:selected-row-keys":function($event){_vm.selectedRowKeys=$event},"search":_vm.getList},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('OperateBtn',{attrs:{"api":"operateReceiptNote","text":_vm.$t('审核'),"params":{
          action: 'check',
          idList: _vm.selectedRowKeys,
        },"disabled":_vm.selectedRowKeys.length === 0},on:{"success":_vm.searchHandle}}),_c('OperateBtn',{staticClass:"ml10",attrs:{"disabled":!_vm.selectedRowKeys.length,"api":"operateReceiptNote","text":_vm.$t('收款'),"params":{
          action: 'receive',
          idList: _vm.selectedRowKeys,
        }},on:{"success":_vm.searchHandle}})]},proxy:true},{key:"receiptNoteSn",fn:function({ text, record }){return [_c('a',{on:{"click":function($event){return _vm.viewDetail(record)}}},[_vm._v(_vm._s(text))])]}},{key:"receiptAmount",fn:function({ text, record }){return [_c('PriceInput',{attrs:{"value":text,"currency":record.receiptCurrency,"showTxt":true}})]}},{key:"verifiedAmount",fn:function({ text, record }){return [_c('PriceInput',{attrs:{"value":text,"currency":record.receiptCurrency,"showTxt":true}})]}},{key:"unVerifiedAmount",fn:function({ text, record }){return [_c('PriceInput',{attrs:{"value":text,"currency":record.receiptCurrency,"showTxt":true}})]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }