<template>
  <div class="content receipt-note-list">
    <section style="margin-bottom: 1%">
      <a-button type="primary" @click="createHandle">
        <i class="icon iconfont icon-new" style="margin-right: 5px"></i>
        {{ $t('新增付款单') }}
      </a-button>
    </section>
    <div class="form-content">
      <a-row :gutter="16" style="margin-top: 15px">
        <a-col :span="6">
          <a-input
            allow-clear
            v-model.trim="formData.receiptNoteSn"
            :placeholder="$t('收款单号')"
          />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :isBu="true"
            :list="$store.state.settlementBuList"
            :code.sync="formData.businessUnitCode"
            :name.sync="formData.businessUnitName"
            :placeholder="$t(`收款组织`)"
          />
        </a-col>
        <a-col :span="6">
          <CommonQuerySelect
            api="getCommonList"
            :placeholder="$t('客户')"
            :code.sync="formData.customerCode"
            :params="{
              businessUnitCode: formData.businessUnitCode,
              tableName: 'customer',
            }"
          />
        </a-col>
        <a-col :span="6">
          <CommonSelect
            :placeholder="$t('单据类型')"
            :list="receipt_note_type"
            :code.sync="formData.orderType"
          />
        </a-col>
      </a-row>
      <a-row :gutter="16" class="mt10">
        <a-col :span="6">
          <a-button @click="searchHandle(1)" type="primary">
            <i class="iconfont" style="margin-right: 5px">&#xe647;</i>
            {{ $t(`查询`) }}
          </a-button>
          <a-button @click="reset">
            <a-icon type="redo" />
            {{ $t(`重置`) }}
          </a-button>
        </a-col>
      </a-row>
    </div>
    <CommonTable
      ref="CommonTableRef"
      :statusList="receipt_note_status"
      :columns.sync="columns"
      :loading="loading"
      :dataSource="tableData"
      :scroll="{ x: 1500, y: scrollHeight }"
      :showSelect="true"
      :selectedRowKeys.sync="selectedRowKeys"
      :total="total"
      rowKey="id"
      @search="getList"
    >
      <template v-slot:header>
        <OperateBtn
          api="operateReceiptNote"
          :text="$t('审核')"
          :params="{
            action: 'check',
            idList: selectedRowKeys,
          }"
          :disabled="selectedRowKeys.length === 0"
          @success="searchHandle"
        />
        <OperateBtn
          class="ml10"
          :disabled="!selectedRowKeys.length"
          api="operateReceiptNote"
          :text="$t('收款')"
          :params="{
            action: 'receive',
            idList: selectedRowKeys,
          }"
          @success="searchHandle"
        />
      </template>
      <template v-slot:receiptNoteSn="{ text, record }">
        <a @click="viewDetail(record)">{{ text }}</a>
      </template>
      <template v-slot:receiptAmount="{ text, record }">
        <PriceInput :value="text" :currency="record.receiptCurrency" :showTxt="true" />
      </template>
      <template v-slot:verifiedAmount="{ text, record }">
        <PriceInput :value="text" :currency="record.receiptCurrency" :showTxt="true" />
      </template>
      <template v-slot:unVerifiedAmount="{ text, record }">
        <PriceInput :value="text" :currency="record.receiptCurrency" :showTxt="true" />
      </template>
    </CommonTable>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations } from 'vuex'
import http from '@/service/axios'
import api from '@/service/api'
import CommonTable from '@/component/commonTable'
import { convertKeysToCamelCase } from '@/common'
export default {
  name: 'ReceiptNoteList',
  components: { CommonTable },
  data() {
    return {
      docType: 'ReceiptNote',
      formData: {},
      tableData: [],
      selectedRowKeys: [],
      total: 0,
      loading: false,

      columns: [
        {
          title: this.$t('收款单号'),
          dataIndex: 'receiptNoteSn',
          width: 200,
          scopedSlots: { customRender: 'receiptNoteSn' },
        },
        {
          title: this.$t('单据类型'),
          dataIndex: 'orderType',
          customRender: (text) => this.receiptNoteTypeMapping[text],
        },
        {
          title: this.$t('单据状态'),
          dataIndex: 'status',
          customRender: (text) => this.receiptNoteStatusMapping[text],
        },
        {
          title: this.$t('收款组织'),
          dataIndex: 'businessUnitName',
        },
        {
          title: this.$t('客户'),
          dataIndex: 'customerName',
        },
        {
          title: this.$t('业务日期'),
          dataIndex: 'bizDate',
        },
        {
          title: this.$t('收款币别'),
          dataIndex: 'receiptCurrency',
          customRender: (text) => this.currencyListMapping[text],
        },
        {
          title: this.$t('收款金额'),
          dataIndex: 'receiptAmount',
          scopedSlots: { customRender: 'receiptAmount' },
        },
        {
          title: this.$t('已核销金额'),
          dataIndex: 'verifiedAmount',
          scopedSlots: { customRender: 'verifiedAmount' },
        },
        {
          title: this.$t('未核销金额'),
          dataIndex: 'unVerifiedAmount',
          scopedSlots: { customRender: 'unVerifiedAmount' },
        },
        {
          title: this.$t('结算方式'),
          dataIndex: 'settlementMethodName',
        },
        {
          title: this.$t('是否生成凭证'),
          dataIndex: 'voucherGenerated',
          width: 100,
          customRender: (text) => (text ? this.$t('是') : this.$t('否')),
        },
        {
          title: this.$t('凭证号'),
          dataIndex: 'voucherNo',
          width: 100,
        },
      ],
    }
  },
  activated() {
    this.searchHandle()
  },
  computed: {
    ...mapState(['receipt_note_status', 'receipt_note_type']),
    ...mapGetters(['receiptNoteTypeMapping', 'receiptNoteStatusMapping', 'currencyListMapping']),
    scrollHeight() {
      return window.innerHeight - 470
    },
  },
  methods: {
    ...mapMutations(['addPane']),
    reset() {
      Object.assign(this.$data, this.$options.data())
      this.searchHandle(1)
    },
    searchHandle(pageNo) {
      this.selectedRowKeys = []
      this.$refs.CommonTableRef.queryList(pageNo)
    },
    createHandle() {
      this.$router.push({ name: 'receiptNoteDetail' })
    },
    async getList(params) {
      this.loading = true
      await http({
        url: api.getReceiptNoteList,
        data: {
          ...this.formData,
          ...params,
        },
        success: (res) => {
          let result = convertKeysToCamelCase(res.result)
          this.tableData = result.list
          this.total = res.result.total
        },
      })
      this.loading = false
    },
    viewDetail(record) {
      this.addPane({
        view: 'receiptNoteDetail/' + record.receiptNoteSn,
        name: 'detailSn',
        query: {
          id: record.id,
          sn: record.receiptNoteSn,
        },
        route: `/receivableManage/receiptNoteDetail?id=${record.id}&sn=${record.receiptNoteSn}`,
      })
    },
  },
}
</script>
